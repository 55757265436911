import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import PageContext from "../context/pageContext";

type BlogPostNode = {
  node: {
    id: string;
    fields: { slug: string };
    frontmatter: {
      title: string;
      date: Date;
    };
    excerpt: string;
    body: string;
  };
};

interface BlogPageProps {
  data: {
    allMdx: {
      edges: [BlogPostNode];
    };
  };
}

const BlogPage: React.FC<BlogPageProps> = ({ data }) => {
  return (
    <PageContext.Consumer>
      {({ toggleMenu }) => (
        <Layout>
          <Seo title="blog" />

          {data.allMdx.edges.map(({ node }) => (
            <div className="post" key={node.id}>
              <h3 className="post__header--index">
                <Link
                  to={node.fields.slug}
                  onClick={() => toggleMenu("closed")}
                >
                  {node.frontmatter.title}
                </Link>
              </h3>
              <h4 className="post__date">{node.frontmatter.date}</h4>
            </div>
          ))}
        </Layout>
      )}
    </PageContext.Consumer>
  );
};

export default BlogPage;

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY", locale: "en-US")
          }
          excerpt
          body
        }
      }
    }
  }
`;
